/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getProfile,
  getProvinces,
  getCities,
  uploadBanner,
  uploadProfilePhoto,
  updateProfile,
  getListRoomStyle,
  getBenefits,
  addBenefit,
  deleteBenefit,
  updateBenefit,
} from '../../helper/function';
import {
  getBase64StringFromDataURL,
  addDefaultSrc,
  formatMoney,
} from '../../helper/lib';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

const Profile = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationPathOne = location.pathname.split('/')[1];
  const isEditProfile = true;
  const [isEditBanner, setIsEditBanner] = useState(false);
  const [isEditProfilePhoto, setIsEditProfilePhoto] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [citiesWarehouseOptions, setCitiesWarehouseOptions] = useState([]);
  const [provinceWarehouseOptions, setProvinceWarehouseOptions] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedWarehouseProvince, setSelectedWarehouseProvince] =
    useState(null);
  const [selectedWarehouseCity, setSelectedWarehouseCity] = useState(null);

  const [postalCode, setPostalCode] = useState('');
  const [warehousePostalCode, setWarehousePostalCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [warehousePhoneNumber, setWarehousePhoneNumber] = useState('');

  const [bannerCropper, setBannerCropper] = useState();
  const [bannerImage, setBannerImage] = useState('');

  const [profilePhotoCropper, setProfilePhotoCropper] = useState();
  const [profilePhotoImage, setProfilePhotoImage] = useState('');

  const [selectedAvailableStatus, setSelectedAvailableStatus] = useState('');
  const [styles, setStyles] = useState([]);
  const [styleSelected, setStyleSelected] = useState([]);

  const [isUploadingBanner, setIsUploadingBanner] = useState(false);
  const [isUploadingProfilePhoto, setIsUploadingProfilePhoto] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [benefits, setBenefits] = useState([]);
  const bannerFileInput = useRef(null);
  const profilePhotoFileInput = useRef(null);

  const onAttachBanner = (e) => {
    e.preventDefault();

    if (e?.target?.files.length <= 0) return;

    let selectedFile = e?.target?.files[0];

    const MAX_FILE_SIZE = 2048; // 2MB
    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error('File size banner is more than 2MB');
      return;
    }

    setIsEditBanner(true);

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setBannerImage(reader.result);
    };

    reader.readAsDataURL(files[0]);
  };

  const onAttachProfilePhoto = (e) => {
    e.preventDefault();

    if (e?.target?.files.length <= 0) return;

    let selectedFile = e?.target?.files[0];

    const MAX_FILE_SIZE = 2048; // 2MB
    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error('File size profile is more than 2MB');
      return;
    }

    setIsEditProfilePhoto(true);

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setProfilePhotoImage(reader.result);
    };

    reader.readAsDataURL(files[0]);
  };

  const getBannerCropData = () => {
    return new Promise((dispatch) => {
      if (typeof bannerCropper !== 'undefined') {
        setBannerImage(bannerCropper.getCroppedCanvas().toDataURL());
        dispatch(bannerCropper.getCroppedCanvas().toDataURL());
      }
    });
  };

  const getProfilePhotoCropData = () => {
    return new Promise((dispatch) => {
      if (typeof profilePhotoCropper !== 'undefined') {
        setProfilePhotoImage(
          profilePhotoCropper.getCroppedCanvas().toDataURL()
        );
        dispatch(profilePhotoCropper.getCroppedCanvas().toDataURL());
      }
    });
  };

  const handleEditBanner = (e) => {
    e.preventDefault();
    bannerFileInput.current.click();
  };

  const handleEditProfilePhoto = (e) => {
    e.preventDefault();
    profilePhotoFileInput.current.click();
  };

  const handleSaveEditBanner = async (e) => {
    e.preventDefault();
    setIsEditBanner(false);
    let bannerFile = await getBannerCropData();
    if (bannerFile) await uploadBannerToApi(bannerFile);
  };

  const handleSaveEditProfilePhoto = async (e) => {
    e.preventDefault();
    setIsEditProfilePhoto(false);
    let profilePhotoFile = await getProfilePhotoCropData();

    if (profilePhotoFile) await uploadProfilePhotoToApi(profilePhotoFile);
  };

  const uploadBannerToApi = async (bannerFile) => {
    setIsUploadingBanner(true);

    let photo = await getBase64StringFromDataURL(bannerFile);

    const dataSend = {
      photo: photo,
    };

    const loadingToast = toast.loading('Uploading...');

    let response = await uploadBanner(dataSend);

    if (response.message === 'Success') {
      getUserData();
      setIsUploadingBanner(false);
      toast.update(loadingToast, {
        render: 'Banner image changed',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      setIsUploadingBanner(false);
      toast.update(loadingToast, {
        render: response.message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const uploadProfilePhotoToApi = async (profilePhotoFile) => {
    setIsUploadingProfilePhoto(true);

    const dataSend = {
      photo: getBase64StringFromDataURL(profilePhotoFile),
    };
    const loadingToast = toast.loading('Uploading...');

    let response = await uploadProfilePhoto(dataSend);

    if (response.message === 'Success') {
      getUserData();
      setIsUploadingProfilePhoto(false);
      toast.update(loadingToast, {
        render: 'Logo image changed',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      setIsUploadingProfilePhoto(false);
      toast.update(loadingToast, {
        render: response.message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const customStyles = {
    city: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
    province: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
  };

  const getProvinceData = async () => {
    const response = await getProvinces();
    if (response.message === 'Success' && response.data.length > 0) {
      let province = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      setProvinceOptions(province);
      setProvinceWarehouseOptions(province);
    } else {
      setProvinceOptions([]);
    }
  };

  const getCitiesData = async (id = 1, type = 'office', firstLoad = false) => {
    const response = await getCities(id);
    if (response.message === 'Success' && response.data.length > 0) {
      let cities = response.data.map((item) => {
        return { value: item.id, label: item.name };
      });
      if (type === 'office') setCitiesOptions(cities);
      if (type === 'warehouse') setCitiesWarehouseOptions(cities);
      if (selectedProvince && type === 'office' && !firstLoad)
        handleChangeCity(cities[0]);
      if (selectedWarehouseProvince && type === 'warehouse' && !firstLoad)
        handleChangeWarehouseCity(cities[0]);
    } else {
      if (type === 'office') setCitiesOptions([]);
      if (type === 'warehouse') setCitiesWarehouseOptions([]);
    }
  };

  //   const handleChangeServicePackage = (e) => {
  //     setSelectedServicePackage(e);
  //   };

  const handleChangeProvince = (e) => {
    setSelectedProvince(e);
    setValue('region_id', e.value);
    getCitiesData(e.value);
  };

  const handleChangeCity = (e) => {
    setSelectedCity(e);
    setValue('district_id', e.value);
  };

  const handleChangeWarehouseProvince = (e) => {
    setSelectedWarehouseProvince(e);
    setValue('wh_region_id', e.value);
    getCitiesData(e.value, 'warehouse');
  };

  const handleChangeWarehouseCity = (e) => {
    setSelectedWarehouseCity(e);
    setValue('wh_district_id', e.value);
  };

  const handleChangeAvailableStatus = (e) => {
    setSelectedAvailableStatus(e.target.value);
    setValue('availableStatus', e.target.value);
  };

  const handleChangeStyle = (selectedOption) => {
    setStyleSelected(selectedOption);

    // Set value
    let currentValue = [];

    selectedOption.map((option, index) => currentValue.push(option.value));

    if (currentValue?.length > 0) {
      setValue('styleProject', JSON.stringify(currentValue));
    } else {
      setValue('styleProject', '');
    }
  };

  const getStyleLists = async () => {
    const response = await getListRoomStyle();
    if (response.message === 'Success') {
      let styleTags = [];
      response?.data.map((val, index) =>
        styleTags.push({
          value: val?.id,
          label: val?.name,
        })
      );
      setStyles(styleTags);

      // set selected style
      if (props.dataProfile?.styleProject) {
        let parsed = JSON.parse(props.dataProfile?.styleProject);
        let selecteds = [];

        parsed.forEach((data, index) => {
          let selectedData = styleTags.find(
            (styleTag) => parseInt(styleTag.value) === parseInt(data)
          );
          if (selectedData) {
            selecteds.push(selectedData);
          }
        });
        setStyleSelected(selecteds);
      }
    }
  };

  // useEffect(() => {
  //     getCitiesData(selectedWarehouseProvince?.value, "warehouse");
  // }, [selectedWarehouseProvince]);

  // useEffect(() => {
  //     getCitiesData(selectedProvince?.value);
  // }, [selectedProvince]);

  let validationSchema;
  if (locationPathOne === 'designer') {
    validationSchema = Yup.object().shape({
      name: Yup.string().required('Name is Required'),
      email: Yup.string()
        .email('Please enter valid email address')
        .required('Email is required'),
      about_merchant: Yup.string().required('Bio is required'),
      experience: Yup.string().required('Experience is required'),
      availableStatus: Yup.string().required('Available Status is required'),
      domain: Yup.string().required('Website is required'),
      instagram: Yup.string().required('instagram is required'),
      use_custom_project: Yup.boolean(),
      cp_description: Yup.string().required(
        'Custom project description is required'
      ),
      cp_price_per_m: Yup.string().required('Custom project price is required'),
      styleProject: Yup.string().required('Style is required'),
    });
  } else {
    validationSchema = Yup.object().shape({
      name: Yup.string().required('Name is Required'),
      email: Yup.string()
        .email('Please enter valid email address')
        .required('Email is required'),
      general_message: Yup.string().required('General Message is required'),
      about_merchant: Yup.string().required('Bio is required'),
      domain: Yup.string().required('Website is required'),
      instagram: Yup.string().required('instagram is required'),
      address: Yup.string().required('Address is Required'),
      address_2: Yup.string(),
      region_id: Yup.number().required('Province is Required'),
      district_id: Yup.number().required('City is Required'),
      postal_code: Yup.string().required('Postcode is Required'),
      phone: Yup.string().required('Phone number is Required'),
      wh_address: Yup.string().required('Address is Required'),
      wh_address_2: Yup.string(),
      wh_region_id: Yup.number().required('Province is Required'),
      wh_district_id: Yup.number().required('City is Required'),
      wh_postal_code: Yup.string().required('Postcode is Required'),
      wh_phone: Yup.string().required('Phone number is Required'),
      // phone: Yup.string()
      //     .required("Phone is Required")
      //     .min(10, "Phone Number should be 10 digit or more")
      //     .test("phone", "Phone Number should start with 0", function (value) {
      //         return value?.substring(0, 1) === "0";
      //     }),
      // region_id: Yup.string().required("Province is Required"),
      // district_id: Yup.string().required("City is Required"),
      // postal_code: Yup.string().required("Postcode is Required"),
      //     .required("Postal Code is Required")
      //     .min(5, "Postal Code minimum is 5 digit")
      //     .max(5, "Postal Code maximum is 5 digit"),
      // wh_region_id: Yup.string().required("Warehouse Province is Required"),
      // wh_district_id: Yup.string().required("Warehouse City is Required"),
      // wh_postal_code: Yup.string().required("Warehouse Postcode is Required"),
      // wh_phone: Yup.string().required("Warehouse Phone is Required"),
    });
  }
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm(formOptions);

  const getUserData = async () => {
    let res = await getProfile();

    if (res.meta.total > 0) {
      props.changeDataProfile(res.data);
      props.setLoading(false);
    } else {
      console.error({ res });
    }
  };

  const onSubmitProfile = (data) => {
    setErrorMessage(null);
    setIsSubmitting(true);
    if (
      locationPathOne === 'designer' &&
      (!benefits || (benefits && benefits.length === 0))
    ) {
      setErrorMessage('Benefit is required');
      setIsSubmitting(false);
      return;
    }
    const loadingToast = toast.loading('Updating profile...');
    const payload = {
      ...data,
      cp_price_per_m: parseInt(String(data.cp_price_per_m).replaceAll('.', '')),
    };

    updateProfile(payload)
      .then((response) => {
        if (response.message === 'Success') {
          getUserData();
          toast.update(loadingToast, {
            render: 'Profile updated',
            type: 'success',
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          if (locationPathOne === 'designer') {
            navigate('/designer');
            return;
          }
          navigate('/');
        } else {
          setErrorMessage(response.message);
          toast.update(loadingToast, {
            render: response.message,
            type: 'error',
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onChangePostCode = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue('postal_code', e.target.value);
      setPostalCode(e.target.value);
    }
  };

  const onChangeWarehousePostCode = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue('wh_postal_code', e.target.value);
      setWarehousePostalCode(e.target.value);
    }
  };

  const onChangePhoneNumber = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue('phone', e.target.value);
      setPhoneNumber(e.target.value);
    }
  };

  const onChangeWarehousePhoneNumber = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue('wh_phone', e.target.value);
      setWarehousePhoneNumber(e.target.value);
    }
  };

  const fetchBenefits = async () => {
    const response = await getBenefits();
    if (response && response.status === 'success') {
      const newInputs = response?.data?.map((input) => ({
        ...input,
        error: false,
      }));
      setBenefits(newInputs);
      return;
    }

    toast.error(response?.message);
  };

  const handleChangeBenefit = (index, event) => {
    const newInputs = [...benefits];
    newInputs[index] = { ...newInputs[index], name: event.target.value };
    setBenefits(newInputs);
  };

  const addInputBenefit = () => {
    const newId =
      benefits.length > 0 ? benefits[benefits.length - 1].id + 1 : 1;
    setBenefits([...benefits, { id: `new-${newId}`, name: '' }]);
  };

  const saveBenefits = async (data, index) => {
    if (data && !data.name) {
      const newInputs = [...benefits];
      newInputs[index] = { ...newInputs[index], error: true };
      setBenefits(newInputs);
      return;
    }

    if (typeof data?.id === 'string' || data?.id instanceof String) {
      const response = await addBenefit({ name: data?.name });

      if (response && response.status === 'error') {
        toast.error(response?.message);
        return;
      }

      toast.success(response?.message);
      fetchBenefits();
      return;
    }
    const response = await updateBenefit(data);

    if (response && response.status === 'error') {
      toast.error(response?.message);
      return;
    }

    toast.success(response?.message);
    fetchBenefits();
  };

  const removeBenefit = async (data, index) => {
    if (typeof data?.id === 'number' || data?.id instanceof Number) {
      const response = await deleteBenefit(data);

      if (response && response.status === 'error') {
        toast.error(response?.message);
        return;
      }

      toast.success(response?.message);
      fetchBenefits();
      return;
    }

    const newInputs = benefits.filter((_, i) => i !== index);
    setBenefits(newInputs);
  };

  React.useEffect(() => {
    getProvinceData();
    if (!props.dataProfile) {
      getUserData();
    }

    // set profile
    let findProvince = {
      value: props.dataProfile?.region?.id,
      label: props.dataProfile?.region?.name,
    };
    if (provinceOptions.length > 0)
      findProvince = provinceOptions.find(
        (item) => item.value === props.dataProfile?.region?.id
      );
    if (findProvince) {
      setSelectedProvince(findProvince);
      setValue('region_id', findProvince.value);
    }
    getCitiesData(findProvince?.value, 'office', true);
    let findCity = {
      value: props.dataProfile?.district?.id,
      label: props.dataProfile?.district?.name,
    };
    if (findCity) {
      setSelectedCity(findCity);
      setValue('district_id', findCity.value);
    }

    let findWHProvince = {
      value: props.dataProfile?.wh_region?.id,
      label: props.dataProfile?.wh_region?.name,
    };
    if (provinceOptions.length > 0)
      findWHProvince = provinceOptions.find(
        (item) => item.value === props.dataProfile?.wh_region?.id
      );
    if (findWHProvince) {
      setSelectedWarehouseProvince(findWHProvince);
      setValue('wh_region_id', findWHProvince.value);
    }
    getCitiesData(findWHProvince?.value, 'warehouse', true);
    let findWHCity = {
      value: props.dataProfile?.wh_district?.id,
      label: props.dataProfile?.wh_district?.name,
    };
    if (findWHCity) {
      setSelectedWarehouseCity(findWHCity);
      setValue('wh_district_id', findWHCity.value);
    }

    setPostalCode(props.dataProfile?.postal_code);
    setPhoneNumber(props.dataProfile?.phone);
    setWarehousePostalCode(props.dataProfile?.wh_postal_code);
    setWarehousePhoneNumber(props.dataProfile?.wh_phone);
    setSelectedAvailableStatus(props.dataProfile?.availableStatus);

    setValue('name', props.dataProfile?.name);
    setValue('email', props.dataProfile?.email);
    setValue('about_merchant', props.dataProfile?.about_merchant);
    setValue('general_message', props.dataProfile?.general_message);
    setValue('domain', props.dataProfile?.domain ?? '');
    setValue('instagram', props.dataProfile?.instagram);
    setValue('address', props.dataProfile?.address);
    setValue('address_2', props.dataProfile?.address_2 ?? '');
    setValue('postal_code', props.dataProfile?.postal_code);
    setValue('phone', props.dataProfile?.phone);
    setValue('wh_address', props.dataProfile?.wh_address);
    setValue('wh_address_2', props.dataProfile?.wh_address_2 ?? '');
    setValue('wh_postal_code', props.dataProfile?.wh_postal_code);
    setValue('wh_phone', props.dataProfile?.wh_phone);
    setValue('use_custom_project', props.dataProfile?.use_custom_project);
    setValue('cp_description', props.dataProfile?.cp_description ?? '');
    setValue(
      'cp_price_per_m',
      formatMoney(props.dataProfile?.cp_price_per_m, '')
    );
    setValue('availableStatus', props.dataProfile?.availableStatus ?? '');
    setValue(
      'styleProject',
      props.dataProfile?.styleProject === '[]' ||
        props.dataProfile?.styleProject === null ||
        props.dataProfile?.styleProject === '' ||
        props.dataProfile?.styleProject === undefined
        ? ''
        : props.dataProfile?.styleProject
    );
    setValue('experience', props.dataProfile?.experience ?? '');

    setBannerImage(
      props.dataProfile?.url_banner.length > 0
        ? props.dataProfile?.url_banner
        : '#'
    );
    setProfilePhotoImage(
      props.dataProfile?.logo.length > 0
        ? props.dataProfile?.logo
        : '/dist/img/logo.svg'
    );

    // set selected style
    if (props.dataProfile?.styleProject) {
      let parsed = JSON.parse(props.dataProfile?.styleProject);
      let selecteds = [];

      parsed.forEach((data, index) => {
        let selectedData = styles.find(
          (styleTag) => parseInt(styleTag.value) === parseInt(data)
        );
        if (selectedData) {
          selecteds.push(selectedData);
        }
      });
      setStyleSelected(selecteds);
    }
  }, [props.dataProfile]);

  useEffect(() => {
    // set selected style
    if (props.dataProfile?.styleProject) {
      let parsed = JSON.parse(props.dataProfile?.styleProject);
      let selecteds = [];

      parsed.forEach((data, index) => {
        let selectedData = styles.find(
          (styleTag) => parseInt(styleTag.value) === parseInt(data)
        );
        if (selectedData) {
          selecteds.push(selectedData);
        }
      });
      setStyleSelected(selecteds);
    }
  }, [styles]);

  useEffect(() => {
    getUserData();
    getStyleLists();
  }, []);

  useEffect(() => {
    if (locationPathOne === 'designer') {
      fetchBenefits();
    }
  }, [locationPathOne]);

  return (
    <div className="tab-content" id="pills-tabContent">
      {/* <!-- Profile --> */}
      <div
        className="tab-pane fade show active"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <form onSubmit={handleSubmit(onSubmitProfile)}>
          <div className="w-100 position-relative img-profile-wrapper">
            <div
              className="w-100 d-flex align-items-center justify-content-center rounded-lg overflow-hidden position-relative back-profile"
              style={{ border: '1px solid #e2e2e2' }}
            >
              {!isEditBanner && (
                <div className="img-wrap w-100 h-100 object-fit-cover">
                  <img
                    alt=""
                    src={bannerImage}
                    onError={addDefaultSrc}
                    className="object-fit-cover"
                  />
                </div>
              )}
              {isUploadingBanner && (
                <div
                  className="bg-white w-100 h-100"
                  style={{ position: 'absolute', filter: 'opacity(0.5)' }}
                ></div>
              )}
              {isEditBanner && (
                <Cropper
                  src={bannerImage}
                  style={{ height: '20rem', width: '100%' }}
                  // Cropper.js options
                  guides={true}
                  viewMode={3}
                  zoomOnTouch={false}
                  toggleDragModeOnDblclick={false}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  dragMode="move"
                  height={'20rem'}
                  width={'2000px'}
                  autoCropArea={1}
                  cropBoxData={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  onInitialized={(instance) => {
                    setBannerCropper(instance);
                  }}
                />
              )}
              <div className="btn-back-background">
                <input
                  accept="image/*"
                  type="file"
                  ref={bannerFileInput}
                  onChange={onAttachBanner}
                  className="d-none"
                />
                {!isEditBanner && !isUploadingBanner && (
                  <>
                    <button
                      className="btn btn-fill border d-flex justify-content-center align-items-center"
                      onClick={handleEditBanner}
                    >
                      <div className="img-wrap">
                        <img
                          alt=""
                          src="../dist/img/custom/camera.svg"
                          className="filter-white icon-2"
                        />
                      </div>
                      <div className="ms-2 d-none d-md-block">
                        Edit Cover Image
                      </div>
                    </button>
                  </>
                )}
                {(isEditBanner || isUploadingBanner) && (
                  <button
                    className={`btn btn-line-gray border d-flex justify-content-center align-items-center ${
                      isUploadingBanner ? 'disabled' : ''
                    }`}
                    onClick={handleSaveEditBanner}
                    disabled={isUploadingBanner}
                  >
                    {!isUploadingBanner && (
                      <div className="img-wrap">
                        <img
                          alt=""
                          src="../dist/img/custom/ic-done.svg"
                          className=" icon-2"
                        />
                      </div>
                    )}
                    <div className="ms-2 d-none d-md-block">
                      {isUploadingBanner ? 'Uploading..' : 'Save Cover Image'}
                    </div>
                  </button>
                )}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center justify-content-md-start ps-md-5 img-profile">
              <div className="av-circle overflow-unset position-relative">
                <div
                  className="av-circle border-white border"
                  style={
                    isUploadingProfilePhoto ? { filter: 'opacity(0.5)' } : {}
                  }
                >
                  {!isEditProfilePhoto && (
                    <img
                      alt=""
                      src={profilePhotoImage}
                      onError={addDefaultSrc}
                    />
                  )}
                  {isEditProfilePhoto && (
                    <Cropper
                      src={profilePhotoImage}
                      // style={{ height: "12rem", width: "12rem" }}
                      // Cropper.js options
                      guides={true}
                      viewMode={3}
                      zoomOnTouch={false}
                      toggleDragModeOnDblclick={false}
                      cropBoxMovable={false}
                      cropBoxResizable={false}
                      dragMode="move"
                      autoCropArea={1}
                      cropBoxData={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      onInitialized={(instance) => {
                        setProfilePhotoCropper(instance);
                      }}
                    />
                  )}
                </div>

                {locationPathOne === 'designer' && (
                  <div
                    className="position-absolute btn-profile-background"
                    style={{ right: '9rem' }}
                  >
                    <div
                      className="img-wrap"
                      style={{ height: '32px', width: '32px' }}
                    >
                      {props?.dataProfile?.group_name?.toLowerCase() ===
                        'platinum' && (
                        <img
                          src={`/dist/img/profile/group-platinum.png`}
                          alt="group-img"
                        />
                      )}
                      {props?.dataProfile?.group_name?.toLowerCase() ===
                        'elite' && (
                        <img
                          src={`/dist/img/profile/group-platinum.png`}
                          alt="group-img"
                        />
                      )}
                      {props?.dataProfile?.group_name?.toLowerCase() ===
                        'celebrity' && (
                        <img
                          src={`/dist/img/profile/group-platinum.png`}
                          alt="group-img"
                        />
                      )}
                    </div>
                  </div>
                )}

                <div className="position-absolute btn-profile-background">
                  <input
                    accept="image/*"
                    type="file"
                    ref={profilePhotoFileInput}
                    onChange={onAttachProfilePhoto}
                    className="d-none"
                  />
                  {!isEditProfilePhoto && !isUploadingProfilePhoto && (
                    <button
                      className="btn btn-fill border rounded-circle icon-hw-b-1 p-0 d-flex align-items-center justify-content-center"
                      onClick={handleEditProfilePhoto}
                    >
                      <div className="img-wrap">
                        <img
                          alt=""
                          src="../dist/img/custom/camera.svg"
                          className="filter-white"
                        />
                      </div>
                    </button>
                  )}
                  {(isEditProfilePhoto || isUploadingProfilePhoto) && (
                    <button
                      className={`btn btn-line-gray border rounded-circle icon-hw-b-1 p-0 d-flex align-items-center justify-content-center ${
                        isUploadingProfilePhoto ? 'disabled' : ''
                      }`}
                      onClick={handleSaveEditProfilePhoto}
                      disabled={isUploadingProfilePhoto}
                    >
                      {!isUploadingProfilePhoto && (
                        <div className="img-wrap">
                          <img
                            alt=""
                            src="../dist/img/custom/ic-done.svg"
                            className=" icon-2"
                          />
                        </div>
                      )}
                      {isUploadingProfilePhoto && (
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ width: '1.5rem', height: '1.5rem' }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
            {locationPathOne === 'designer' && (
              <div
                className="w-100 d-flex align-items-center justify-content-end rounded-lg overflow-hidden position-relative mt-2"
                style={{ gap: '5px' }}
              >
                {props?.dataProfile?.group_name?.toLowerCase() ===
                  'platinum' && (
                  <img
                    src={`/dist/img/profile/group-platinum.png`}
                    alt="group-img"
                    style={{ height: '24px', width: '24px' }}
                  />
                )}
                {props?.dataProfile?.group_name?.toLowerCase() === 'elite' && (
                  <img
                    src={`/dist/img/profile/group-platinum.png`}
                    alt="group-img"
                    style={{ height: '24px', width: '24px' }}
                  />
                )}
                {props?.dataProfile?.group_name?.toLowerCase() ===
                  'celebrity' && (
                  <img
                    src={`/dist/img/profile/group-platinum.png`}
                    alt="group-img"
                    style={{ height: '24px', width: '24px' }}
                  />
                )}

                <span className="fs-5 fw-bold mb-0">
                  {props?.dataProfile?.group_name}
                </span>
              </div>
            )}
          </div>

          {/* Input Khusus Designer */}
          {locationPathOne === 'designer' && (
            <>
              <div className="fs-5 fw-bold mb-4">
                Profile Information
                <i className="fa fas-pencil"></i>
              </div>
              {errorMessage !== null && (
                <div className="alert alert-danger alert-dismissible fade show">
                  {errorMessage}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setErrorMessage(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Name<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.name}
                    {...register('name')}
                  />
                  {errors.name ? (
                    <span className="text-danger">{errors.name.message}</span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Email<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.email}
                    {...register('email')}
                  />
                  {errors.email ? (
                    <span className="text-danger">{errors.email.message}</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Bio<div className="text-danger ps-1">*</div>
                  </label>
                  <textarea
                    disabled={!isEditProfile}
                    className="form-control"
                    rows="3"
                    defaultValue={props.dataProfile?.about_merchant}
                    {...register('about_merchant')}
                  ></textarea>
                  {errors.about_merchant ? (
                    <span className="text-danger">
                      {errors.about_merchant.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Experience (Years)<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="number"
                    className="form-control"
                    defaultValue={props.dataProfile?.experience}
                    {...register('experience')}
                    min={0}
                  />
                  {errors.experience ? (
                    <span className="text-danger">
                      {errors.experience.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Available Status<div className="text-danger ps-1">*</div>
                  </label>
                  <select
                    value={selectedAvailableStatus}
                    onChange={handleChangeAvailableStatus}
                    className="form-select"
                  >
                    <option value="">Pilih</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Nearly Full">Nearly Full</option>
                  </select>
                  {errors.availableStatus ? (
                    <span className="text-danger">
                      {errors.availableStatus.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Website (URL)<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.url}
                    {...register('domain')}
                  />
                  {errors.domain ? (
                    <span className="text-danger">{errors.domain.message}</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Instagram Profile (URL)
                    <div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.instagram}
                    {...register('instagram')}
                  />
                  {errors.instagram ? (
                    <span className="text-danger">
                      {errors.instagram.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Style<div className="text-danger ps-1">*</div>
                  </label>
                  <Select
                    isMulti
                    options={styles}
                    value={styleSelected}
                    onChange={handleChangeStyle}
                    menuPlacement="auto"
                  />
                  {errors.styleProject ? (
                    <span className="text-danger">
                      {errors.styleProject.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="fs-5 fw-bold mt-5 mb-4">
                Custom Project
                <i className="fa fas-pencil"></i>
              </div>

              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <div className="mb-3" key="cp-1">
                    <label className="form-label d-flex fw-semibold">
                      Price (Per Meter)
                      <div className="text-danger ps-1">*</div>
                    </label>
                    <input
                      {...register('cp_price_per_m')}
                      disabled={!isEditProfile}
                      type="text"
                      className="form-control"
                      onKeyUp={(event) =>
                        setValue(
                          'cp_price_per_m',
                          formatMoney(
                            event.target.value.replaceAll('.', ''),
                            ''
                          )
                        )
                      }
                    />
                    {errors.cp_price_per_m ? (
                      <span className="text-danger">
                        {errors.cp_price_per_m.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="mb-3" key="cp-2">
                    <label className="form-label d-flex fw-semibold">
                      Description<div className="text-danger ps-1">*</div>
                    </label>
                    <textarea
                      disabled={!isEditProfile}
                      className="form-control"
                      rows="3"
                      {...register('cp_description')}
                    ></textarea>
                    {errors.about_merchant ? (
                      <span className="text-danger">
                        {errors.cp_description.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="mb-3" key="cp-3">
                    <div className="d-flex flex-column mb-3">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <label className="form-label d-flex fw-semibold m-0">
                          Benefit<div className="text-danger ps-1">*</div>
                        </label>
                        {benefits && benefits.length === 0 ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={addInputBenefit}
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        ) : null}
                      </div>

                      {errorMessage && benefits && benefits.length === 0 ? (
                        <span className="text-danger">
                          Benefits is required
                        </span>
                      ) : null}
                    </div>

                    {benefits && benefits.length > 0
                      ? benefits.map((benefit, index) => (
                          <div key={benefit.id} className="mb-3">
                            <div
                              className="d-flex flex-row"
                              key={index + 'set'}
                            >
                              <input
                                disabled={!isEditProfile}
                                type="text"
                                className="form-control"
                                value={benefit?.name}
                                onChange={(event) =>
                                  handleChangeBenefit(index, event)
                                }
                              />
                              <div
                                className="btn-group ms-3"
                                role="group"
                                aria-label="benefit-action-button-group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  style={{ width: '45px' }}
                                  onClick={addInputBenefit}
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-warning btn-sm"
                                  style={{ width: '45px' }}
                                  onClick={() =>
                                    saveBenefits(
                                      {
                                        name: benefit?.name,
                                        id: benefit?.id,
                                      },
                                      index
                                    )
                                  }
                                >
                                  {typeof benefit?.id === 'string' ||
                                  benefit?.id instanceof String ? (
                                    <i className="fas fa-save"></i>
                                  ) : (
                                    <i className="fas fa-edit"></i>
                                  )}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  style={{ width: '45px' }}
                                  onClick={() => removeBenefit(benefit, index)}
                                >
                                  <i className="fas fa-trash"></i>
                                </button>
                              </div>
                            </div>
                            {benefit?.error ? (
                              <span className="text-danger">
                                Benefit is required
                              </span>
                            ) : null}
                          </div>
                        ))
                      : null}
                  </div>

                  <div key="cp-4">
                    <label className="form-label d-flex fw-semibold">
                      Status<div className="text-danger ps-1">*</div>
                    </label>
                    <Controller
                      name="use_custom_project"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          label="Status"
                          name={field.name}
                          checked={field.value ?? false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Input Khusus Vendor */}
          {locationPathOne !== 'designer' && (
            <>
              <div className="fs-5 fw-bold mb-4">
                Partner Information
                <i className="fa fas-pencil"></i>
              </div>
              {errorMessage !== null && (
                <div className="alert alert-danger alert-dismissible fade show">
                  {errorMessage}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setErrorMessage(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Name<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.name}
                    {...register('name')}
                  />
                  {errors.name ? (
                    <span className="text-danger">{errors.name.message}</span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Email<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.email}
                    {...register('email')}
                  />
                  {errors.email ? (
                    <span className="text-danger">{errors.email.message}</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    General Message<div className="text-danger ps-1">*</div>
                  </label>
                  <textarea
                    disabled={!isEditProfile}
                    className="form-control"
                    rows="3"
                    defaultValue={props.dataProfile?.general_message}
                    {...register('general_message')}
                  ></textarea>
                  {errors.general_message ? (
                    <span className="text-danger">
                      {errors.general_message.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Bio<div className="text-danger ps-1">*</div>
                  </label>
                  <textarea
                    disabled={!isEditProfile}
                    className="form-control"
                    rows="3"
                    defaultValue={props.dataProfile?.about_merchant}
                    {...register('about_merchant')}
                  ></textarea>
                  {errors.about_merchant ? (
                    <span className="text-danger">
                      {errors.about_merchant.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Website (URL)<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.url}
                    {...register('domain')}
                  />
                  {errors.domain ? (
                    <span className="text-danger">{errors.domain.message}</span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Instagram Profile (URL)
                    <div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.instagram}
                    {...register('instagram')}
                  />
                  {errors.instagram ? (
                    <span className="text-danger">
                      {errors.instagram.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="fs-5 fw-bold mt-5 mb-4">Office Location</div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Address<div className="text-danger ps-1">*</div>
                  </label>
                  <textarea
                    disabled={!isEditProfile}
                    className="form-control"
                    rows="3"
                    defaultValue={props.dataProfile?.address}
                    {...register('address')}
                  ></textarea>
                  {errors.address ? (
                    <span className="text-danger">
                      {errors.address.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Address Street 2
                  </label>
                  <textarea
                    disabled={!isEditProfile}
                    className="form-control"
                    rows="3"
                    defaultValue={props.dataProfile?.address_2 ?? ''}
                    {...register('address_2')}
                  ></textarea>
                  {errors.address_2 ? (
                    <span className="text-danger">
                      {errors.address_2.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Province<div className="text-danger ps-1">*</div>
                  </label>
                  <Controller
                    name="region_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectedProvince}
                        placeholder="Select Province"
                        options={provinceOptions}
                        isSearchable={true}
                        styles={customStyles.province}
                        menuPortalTarget={document.body}
                        onChange={handleChangeProvince}
                        isDisabled={!isEditProfile}
                      />
                    )}
                  />
                  {errors.region_id ? (
                    <span className="text-danger">
                      {errors.region_id.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    City<div className="text-danger ps-1">*</div>
                  </label>
                  <Controller
                    name="district_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectedCity}
                        placeholder="Select City"
                        options={citiesOptions}
                        isSearchable={true}
                        styles={customStyles.city}
                        menuPortalTarget={document.body}
                        onChange={handleChangeCity}
                        isDisabled={!isEditProfile}
                      />
                    )}
                  />
                  {errors.district_id ? (
                    <span className="text-danger">
                      {errors.district_id.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Postcode<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.postal_code}
                    {...register('postal_code')}
                    value={postalCode}
                    onChange={onChangePostCode}
                    maxLength={5}
                  />
                  {errors.postal_code ? (
                    <span className="text-danger">
                      {errors.postal_code.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Phone Number<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.phone}
                    {...register('phone')}
                    value={phoneNumber}
                    onChange={onChangePhoneNumber}
                    maxLength={15}
                  />
                  {errors.phone ? (
                    <span className="text-danger">{errors.phone.message}</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="fs-5 fw-bold mt-5 mb-4">Warehouse Location</div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Address<div className="text-danger ps-1">*</div>
                  </label>
                  <textarea
                    disabled={!isEditProfile}
                    className="form-control"
                    rows="3"
                    defaultValue={props.dataProfile?.wh_address}
                    {...register('wh_address')}
                  ></textarea>
                  {errors.wh_address ? (
                    <span className="text-danger">
                      {errors.wh_address.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Address Street 2
                  </label>
                  <textarea
                    disabled={!isEditProfile}
                    className="form-control"
                    rows="3"
                    defaultValue={props.dataProfile?.wh_address_2 ?? ''}
                    {...register('wh_address_2')}
                  ></textarea>
                  {errors.wh_address_2 ? (
                    <span className="text-danger">
                      {errors.wh_address_2.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Province<div className="text-danger ps-1">*</div>
                  </label>
                  <Controller
                    name="wh_region_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectedWarehouseProvince}
                        placeholder="Select Province"
                        options={provinceWarehouseOptions}
                        isSearchable={true}
                        styles={customStyles.province}
                        menuPortalTarget={document.body}
                        onChange={handleChangeWarehouseProvince}
                        isDisabled={!isEditProfile}
                      />
                    )}
                  />
                  {errors.wh_region_id ? (
                    <span className="text-danger">
                      {errors.wh_region_id.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    City<div className="text-danger ps-1">*</div>
                  </label>
                  <Controller
                    name="wh_district_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectedWarehouseCity}
                        placeholder="Select City"
                        options={citiesWarehouseOptions}
                        isSearchable={true}
                        styles={customStyles.city}
                        menuPortalTarget={document.body}
                        onChange={handleChangeWarehouseCity}
                        isDisabled={!isEditProfile}
                      />
                    )}
                  />
                  {errors.wh_district_id ? (
                    <span className="text-danger">
                      {errors.wh_district_id.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-sm-flex mb-3">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Postcode<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.wh_postal_code}
                    {...register('wh_postal_code')}
                    value={warehousePostalCode}
                    onChange={onChangeWarehousePostCode}
                    maxLength={5}
                  />
                  {errors.wh_postal_code ? (
                    <span className="text-danger">
                      {errors.wh_postal_code.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-12 col-sm-6 ps-sm-2">
                  <label className="form-label d-flex fw-semibold">
                    Phone Number<div className="text-danger ps-1">*</div>
                  </label>
                  <input
                    disabled={!isEditProfile}
                    type="text"
                    className="form-control"
                    defaultValue={props.dataProfile?.wh_phone}
                    {...register('wh_phone')}
                    value={warehousePhoneNumber}
                    onChange={onChangeWarehousePhoneNumber}
                    maxLength={15}
                  />
                  {errors.wh_phone ? (
                    <span className="text-danger">
                      {errors.wh_phone.message}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          )}

          <div className="d-flex justify-content-end">
            {!isSubmitting && (
              <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn w-100 fw-semibold btn-fill px-5 mt-3 ask"
                >
                  Save
                </button>
              </div>
            )}
            {isSubmitting && (
              <button
                type="submit"
                disabled
                className="btn w-sm-100 m-0 m-sm-1 btn-fill fw-semibold"
              >
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: '1.5rem', height: '1.5rem' }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    dataProfile: state.dataProfile,
    loadingGeneral: state.loadingGeneral,
  };
};

const actionCreators = (dispatch) => {
  return {
    setLoading: (data) => dispatch({ type: 'CHANGE_LOADING', payload: data }),
    changeDataProfile: (data) =>
      dispatch({ type: 'CHANGE_DATA_PROFILE', payload: data }),
  };
};

export default connect(mapState, actionCreators)(Profile);
